import React, { Component } from 'react';
import { Accordion, Icon, Message } from 'semantic-ui-react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

class Help extends Component {
  state = {
    activeIndex: -1
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    typeof e;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <>
        <Helmet
          title='eslbot | help'
          meta={[
            { name: 'description', content: 'Need help? Find some answers to questions here' },
            { name: 'keywords', content: 'esl bot english teaching help' }
          ]}
        />
        <Accordion>
          <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
            <Icon name='dropdown' />
            <strong>how do I contact eslbot?</strong>
          </Accordion.Title>
          <Accordion.Content style={styles.content} active={activeIndex === 1}>
            <Message>
              email <a href='mailto:admin@eslbot.com'>admin@eslbot.com</a>
            </Message>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
            <Icon name='dropdown' />
            <strong>how do I delete, edit, or view a list of my job postings?</strong>
          </Accordion.Title>
          <Accordion.Content style={styles.content} active={activeIndex === 2}>
            <Message>account > my jobs</Message>
          </Accordion.Content>
          <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
            <Icon name='dropdown' />
            <strong>how do I change my password?</strong>
          </Accordion.Title>
          <Accordion.Content style={styles.content} active={activeIndex === 3}>
            <Message>account > manage my account</Message>
          </Accordion.Content>

          <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
            <Icon name='dropdown' />
            <strong>how do I post a job?</strong>
          </Accordion.Title>
          <Accordion.Content style={styles.content} active={activeIndex === 4}>
            <Message>
              <Link to='/post-job'>eslbot.com/post-job</Link>
            </Message>
          </Accordion.Content>
          <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
            <Icon name='dropdown' />
            <strong>how do I advertise on eslbot?</strong>
          </Accordion.Title>
          <Accordion.Content style={styles.content} active={activeIndex === 5}>
            <Message>
              email <a href='mailto:admin@eslbot.com'>admin@eslbot.com</a>
            </Message>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }
}

const styles = {
  content: {
    marginLeft: '20px'
  }
};

export default Help;
